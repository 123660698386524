import React, { useState, useEffect } from "react";
import {
  getDocs,
  doc,
  collection,
  query,
  where,
  deleteDoc
} from "firebase/firestore";
import { db,storage,getFilePath } from "../../config/firebase";
import { SmileOutlined } from "@ant-design/icons";
import { Result, Spin, Card, Row, Col, Tag, Button, Popconfirm } from "antd";
import { ref,deleteObject  } from "firebase/storage";


import "./profile.css";

const { Meta } = Card;

export default function MyUploads(props) {
  const [myVideos, setMyVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteConfirmationForVidoId, setOpenDeleteConfirmationForVidoId] =
    useState(null);

  const videoRef = collection(db, "videos");
  const q = query(
    videoRef,
    where("userId", "==", JSON.parse(localStorage.getItem("authUser")).id),
    where("deleted", "==", false)
  );
  useEffect(() => {
    const videos = async () => {
      setIsLoading(true);
      const data = await getDocs(q);
      var list = [];
      data.forEach((doc) => {
        list.push({
          ...doc.data(),
          videoId: doc.id,
        });
      });
      setMyVideos(list);
      setIsLoading(false);
    };
    videos();
  }, []);

  async function deleteVideo(videoId) {
    var video = myVideos.find((v) => v.videoId == videoId);
    console.log(video);
    if(video.output_json_urls!=null){
      video.output_json_urls.forEach(async url => {
        const storageRef = ref(storage,getFilePath(url));
        await deleteObject(storageRef)
      });
    }
    if(video.output_videos_urls!=null){
      video.output_videos_urls.forEach(async url => {
        const storageRef = ref(storage,getFilePath(url));
        await deleteObject(storageRef)
      });
    }
    if(video.output_images_urls!=null){
      video.output_images_urls.forEach(async url => {
        const storageRef = ref(storage,getFilePath(url));
        await deleteObject(storageRef)
      });
    }
    const storageRef = ref(storage,video.filePath);
    await deleteObject(storageRef)
    var videoRef = doc(db, "videos", videoId);
    deleteDoc(videoRef)
    setMyVideos(myVideos.filter(v=>v.videoId!=videoId))
    setOpenDeleteConfirmationForVidoId(null);
  }

  function getVideoCard(v, i) {
    return (
      <Card
        key={i}
        hoverable
        style={{
          width: 240,
        }}
        cover={
          <div>
            <video width="240" height="auto" controls>
              <source src={v.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        }
      >
        <Meta
          title={v.uploadedOn}
          description={
            v.status == "uploaded" ? (
              <Tag color="magenta">{v.status}</Tag>
            ) : v.status == "processing" ? (
              <Tag color="warning">{v.status}</Tag>
            ) : (
              <Tag color="green">{v.status}</Tag>
            )
          }
        />
      </Card>
    );
  }

  return (
    <div>
      {myVideos.length < 1 && !isLoading ? (
        <Result
          icon={<SmileOutlined />}
          title="You dont have uploaded any videos yet!"
        />
      ) : null}

      {isLoading ? (
        <div className="example">
          <Spin />
        </div>
      ) : null}

      <Row gutter={[16, 16]}>
        {myVideos.map((v, i) => {
          return (
            <Col key={i}>
              <div className="my-upload-header">
                <Popconfirm
                  title="Confirm"
                  description="Do you want to delete the video?"
                  open={openDeleteConfirmationForVidoId == v.videoId}
                  onConfirm={() => deleteVideo(v.videoId)}
                  onCancel={() => setOpenDeleteConfirmationForVidoId(null)}
                >
                  <Button
                    onClick={() =>
                      setOpenDeleteConfirmationForVidoId(v.videoId)
                    }
                    size="small"
                    danger
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </div>

              {v.status == "success" ? (
                <a
                  target="_blank"
                  href={`https://renderer.modoai.art/?quality=phone&videoId=${v.videoId}`}
                >
                  {getVideoCard(v, i)}
                </a>
              ) : (
                getVideoCard(v, i)
              )}
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
