// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth ,GoogleAuthProvider} from "firebase/auth";
import { getFirestore  } from "firebase/firestore";
import {getStorage} from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqTa6DuE_AFYL3jGCobLO7e7OtWP6fO6I",
  authDomain: "modo-ai.firebaseapp.com",
  projectId: "modo-ai",
  storageBucket: "modo-ai.appspot.com",
  messagingSenderId: "942097671631",
  appId: "1:942097671631:web:1b9f3f2c01068f5de9deba",
  measurementId: "G-PSRSY8HBQG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider=new GoogleAuthProvider();
const storage = getStorage(app);
const db = getFirestore (app);
const getFilePath=(url)=>{
  return url.split(`${firebaseConfig.storageBucket}/`)[1]
}

export {auth,googleProvider,storage,db,getFilePath}