import { Button, Card, Divider, Layout, Menu, theme } from "antd";
import { ContainerOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import "./profile.css";
import UploadModal from "./upload-modal";

const { Content, Footer, Sider } = Layout;

export default function Profile() {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const navigate = useNavigate();

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout>
      <Content
        style={{
          padding: "0 50px",
          height: "80vh",
        }}
      >
        <Layout
          style={{
            padding: "24px 0",
            background: "#F5F5F5",
          }}
        >
          <Sider
            style={{
              background: "#F5F5F5",
            }}
            width={200}
          >
            <Card>
              <div className="grid" id="side-nav-buttons">
                <Button
                  onClick={() => {
                    navigate("my-uploads");
                  }}
                  className="col-12"
                  icon={<ContainerOutlined />}
                >
                  My Uploads
                </Button>
                <Divider />
                <Button
                  onClick={() => setOpenUploadModal(true)}
                  icon={<PlusOutlined />}
                >
                  Upload
                </Button>
              </div>
            </Card>
          </Sider>
          <Content
            style={{
              padding: "0 24px",
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
        {openUploadModal ? (
          <UploadModal
            open={true}
            handleCancel={() => setOpenUploadModal(false)}
            handleSuccess={() => setOpenUploadModal(false)}
          />
        ) : (
          false
        )}
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        ModoAi ©2023
      </Footer>
    </Layout>
  );
}
