import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import React, { useState } from "react";
import { auth, googleProvider } from "../../config/firebase";

import "./auth.css";
import { Button, Input } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  GoogleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const signIn = () => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setIsLoading(false);
        navigate("/my-uploads");
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const hangleGoogleLogin = async () => {
    var googleSigninResult = await signInWithPopup(auth, googleProvider);
    localStorage.setItem("authUser", JSON.stringify({
      id: googleSigninResult.user.uid,
      displayName: googleSigninResult.user.displayName,
    }));
    navigate("/my-uploads");
  };

  return (
    <div class="form">
      <div class="form-panel one">
        <div class="form-header">
          <h1>Sign In</h1>
        </div>
        <div class="form-content">
          <form id="account">
            <div class="text-danger"></div>
            <div class="form-group">
              <Input
                size="large"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                prefix={<UserOutlined />}
              />
            </div>
            <div class="form-group">
              <Input.Password
                placeholder="Password"
                size="large"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                iconRender={(passwordVisible) =>
                  passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            <div class="form-group">
              <Button
                onClick={signIn}
                loading={isLoading}
                size="large"
                type="primary"
              >
                Sign In
              </Button>
            </div>
          </form>
        </div>
        <div id="google-icon">
          <Button onClick={hangleGoogleLogin}><GoogleOutlined /></Button>
        </div>
      </div>
      <NavLink className="form-panel two" to={"/signup"}>
        {" "}
      </NavLink>
    </div>
  );
};

export default Login;
