import React from "react";
import {Layout } from 'antd';
import { NavLink } from "react-router-dom";
import { Button } from 'antd';

import "./nav.css"

const {Header}=Layout

const MainNav = (props) => {

  return (
    <Header className="header">
      <div>
        <NavLink to={"/"} className="logo">MODO AI</NavLink>
      </div>
      <div id="user-container">
        {props.authUser ? (
          <div id="signed-user">
            <p>{`Hi, ${props.authUser.displayName}.`}</p>
            <Button size="small" type="primary" onClick={props.userSignOut}>Sign Out</Button>
          </div>
        ) : (
          <NavLink to={"login"}>Login</NavLink>
        )}
      </div>
    </Header>
  );
};

export default MainNav;
