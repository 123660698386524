import React, { useState, useEffect } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./config/firebase";
import { ConfigProvider } from "antd";

import {
  BrowserRouter as Router,
  Routes, //replaces "Switch" used till v5
  Route,
  Navigate
} from "react-router-dom";

import Login from "./pages/authentication/login";
import SignUp from "./pages/authentication/signup";
import Profile from "./pages/profile/profile";
import Root from "./pages/root";
import MainNav from "./pages/nav/nav";
import MyUploads from "./pages/profile/my-uploads";

import "./App.css";
import GuardedRoute from "./config/guarded-route";

function App() {
  const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem("authUser")));

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        localStorage.removeItem("authUser")
        setAuthUser(null);
      }
    });

    return () => {
      listen();
    };
  }, []);

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("authUser")
      })
      .catch((error) => console.log(error));
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1677ff",
        },
      }}
    >
      <Router>
        <MainNav userSignOut={userSignOut} authUser={authUser} />
        <Routes>
          <Route path="/" element={<Navigate to="/my-uploads" replace />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route element={<Profile />}>
            <Route
              path="my-uploads"
              element={
                <GuardedRoute isSignedIn={authUser != null}>
                  <MyUploads />
                </GuardedRoute>
              }
            />
          </Route>
          <Route path="*" element={<Root />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
