import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../config/firebase";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
} from "@ant-design/icons";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const signUp = async () => {
    setIsLoading(true);
    try {
      var request = await createUserWithEmailAndPassword(auth, email, password);
      if (request.user) {
        await updateProfile(request.user, {
          displayName: fullName,
        });
        localStorage.setItem("authUser", JSON.stringify({
          id: request.user.uid,
          displayName: request.user.displayName,
        }));
        window.location.href="/my-uploads";
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <div class="form">
      <div class="form-panel one">
        <div class="form-header">
          <h1>Sign Up</h1>
        </div>
        <div class="form-content">
          <form id="account">
            <div class="text-danger"></div>
            <div class="form-group">
              <Input
                size="large"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                prefix={<UserOutlined />}
              />
            </div>
            <div class="form-group">
              <Input
                size="large"
                placeholder="Full Name"
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                prefix={<UserOutlined />}
              />
            </div>
            <div class="form-group">
              <Input.Password
                placeholder="Password"
                size="large"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                iconRender={(passwordVisible) =>
                  passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            <div class="form-group">
              <Button
                onClick={async ()=>await signUp()}
                loading={isLoading}
                size="large"
                type="primary"
              >
                Sign Up
              </Button>
            </div>
          </form>
        </div>
      </div>
      <NavLink className="form-panel two" to={"/login"}>
        {" "}
      </NavLink>
    </div>
  );
};

export default SignUp;
