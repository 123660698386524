import { useState } from "react";
import { Modal } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import {  Upload } from "antd";
import {
  collection,
  addDoc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage,db, } from "../../config/firebase";
const { Dragger } = Upload;

const UploadModal = (props) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progressStatus, setProgressStatus] = useState(null);

  const videosCollection = collection(db, "videos");

  const uploadProps = {
    accept: "video/*",
    name: "file",
    multiple: false,
    customRequest(req) {
      setProgressStatus("uploading");
      var filePath=`/videos/${req.file.name}`
      const storageRef = ref(storage,filePath);
      const uploadTask = uploadBytesResumable(storageRef, req.file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          ); // update progress
          setUploadProgress(percent);
          req.onProgress({percent:percent})
        },
        (err) => {
          setProgressStatus("exception");
        },
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await addDoc(videosCollection, {
              userId: JSON.parse(localStorage.getItem("authUser")).id,
              videoUrl : url,
              status:"uploaded",
              uploadedOn:new Date().toDateString(),
              deleted:false,
              filePath:filePath
            });
            setProgressStatus("success");
            req.onSuccess(url)
          });
        }
      );
    },
    onChange(info) {
    }
  };

  const handleOk = () => {
    window.location.reload()
  };
  const handleCancel = () => {
    props.handleCancel();
  };
  return (
    <Modal
      title="Upload your video"
      okText={"Done"}
      maskClosable={false}
      open={props.open}
      onOk={handleOk}
      okButtonProps={{
        disabled: (progressStatus != null && progressStatus != "success"),
      }}
      cancelButtonProps={{
        disabled: (progressStatus != null && progressStatus != "success"),
      }}
      onCancel={handleCancel}
    >
      <Dragger
        disabled={progressStatus != null && progressStatus != "success"}
        progress={{
          showInfo: (progressStatus != null && progressStatus != "success"),
          status: progressStatus,
        }}
        {...uploadProps}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload video
        </p>
      </Dragger>
    </Modal>
  );
};
export default UploadModal;
